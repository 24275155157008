import { Data } from 'emoji-mart';
import { saveAs } from 'file-saver';
import { json2csv } from 'json-2-csv';
import * as _ from 'lodash';
import moment from 'moment';
import slackMessageParser, { Node, NodeType } from 'slack-message-parser';

import {
  routes,
  CUSTOM_FIELD_TYPE_USER_ATTRIBUTE,
  OUTBOUND_RECIPIENT_TYPE,
  CONVERSATION_PRIORITY_VALUES,
  MESSAGE_SOURCE_TYPE,
} from 'src/app/constants';
import { environment } from 'src/environments/environment';
import { Customer, Organizer, Profile } from 'src/models';
import { ApiService } from 'src/services/api.service';

import { SelectedFilterMap } from '../components/filter-button/filter-button.component';

const replacer = (value: any) => {
  if (typeof value === 'boolean') {
    return value.toString();
    ``;
  }

  if (typeof value === 'number') {
    return String(value);
  }

  if (_.isEmpty(value)) {
    return '';
  }

  if (value instanceof Date) {
    return moment(value).format();
  }

  if (value instanceof Array) {
    return JSON.stringify(value).replace(/,/g, ' ');
  }

  return value;
};

export const downloadCSV = async (
  items: any[],
  columns: string[] | { id: string; label: string }[],
  filename: string,
) => {
  if (!items.length) {
    return;
  }

  const normalizedColumns = columns.map((column) => {
    if (typeof column === 'string') {
      return { field: column, title: column };
    }

    return { field: column.id, title: column.label };
  });

  const blob = new Blob(
    [
      await json2csv(
        items.map((item) =>
          Array.from(Object.entries(item)).reduce((acc, [key, value]) => {
            acc[key] = replacer(value);

            return acc;
          }, {}),
        ),
        {
          keys: normalizedColumns,
        },
      ),
    ],
    { type: 'text/csv' },
  );

  saveAs(blob, `${filename}.csv`);
};

export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) {
    return null;
  }
  return `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`;
};

export const validateEmail = (email?: string) => {
  if (!email) {
    return;
  }

  const cleanEmail = email
    .toLowerCase()
    .replace(/^\s+|\s+$/g, '')
    .replace(',', '');

  if (!/(.+)@(.+){2,}\.(.+){2,}/.test(cleanEmail)) {
    return;
  }

  return cleanEmail;
};

export const durationStringFromMinutes = (minutes: number) => {
  const h = Math.floor(minutes / 60);
  const mins = minutes - h * 60;
  const minSuffix = mins === 0 ? `` : `${mins} min`;
  const hourPlurality = h > 1 ? 's ' : ' ';
  const hourPrefix = h === 0 ? `` : `${h} hour${hourPlurality}`;
  return `${hourPrefix}${minSuffix}`;
};

export const getHtmlPreviewText = (text: string, length = 220) => {
  if (!text) {
    return '';
  }

  const htmlFree = text
    .replace(/<div>/g, ' ')
    .replace(/<br>/g, ' ')
    .replace(/<[^>]*>/g, '')
    .replace(/&#160;/g, ' ')
    .replace(/&amp;/g, '&')
    .replace(/&#34;/g, '"');
  const substring = htmlFree.substring(0, 220);
  return substring.length >= length ? `${substring}...` : substring;
};

export function getCompanyCustomFieldId(field) {
  if (field.type === CUSTOM_FIELD_TYPE_USER_ATTRIBUTE) {
    return getAdditionalIdpFieldId(field.fieldKey);
  }

  return getCustomFieldId(field);
}

export function getCustomFieldId(field) {
  return `customFields.${field.id ?? _.camelCase(field.label)}`;
}

export function getAdditionalIdpFieldId(fieldName) {
  return `additionalIdpFields.${fieldName}`;
}

export function flattenAdditionalIdpFields(additionalIdpFields: Record<string, unknown>, prefix: string[] = []) {
  return Object.entries(additionalIdpFields).reduce((acc, [key, value]) => {
    // Exclude array fields for now.
    // TODO: Maybe figure out a way to handle these at some point.
    if (Array.isArray(value)) {
      return acc;
    }

    const newPrefix = [...prefix, key];

    if (typeof value === 'object' && value !== null) {
      return {
        ...acc,
        ...flattenAdditionalIdpFields(value as Record<string, unknown>, newPrefix),
      };
    }

    return {
      ...acc,
      [newPrefix.join('.')]: value,
    };
  }, {});
}

export function removeUndefinedFields(data: Record<string, any>) {
  return _.omitBy(data, (v) => v === undefined);
}

export function getFirebaseSettings() {
  const isDev = environment.environment === 'dev';
  const firebaseSettings = { ...environment.firebase };

  if (!isDev) {
    if (getAppConfig().useLegacyAuthDomain) {
      firebaseSettings.authDomain = `auth.${environment.rootDomain}`;
    } else {
      firebaseSettings.authDomain = window.location.host;
    }
  }

  return firebaseSettings;
}

export interface Recipient {
  type?: typeof OUTBOUND_RECIPIENT_TYPE.COMMUNITY | typeof OUTBOUND_RECIPIENT_TYPE.EMAIL;
  id?: string;
  email?: string;
  name?: string;
  count?: number;
}

export function createCommunityRecipient(community: Organizer): Recipient {
  return {
    id: community.objectId ?? community.id ?? null,
    name: `${community.name} (${community.followerCount} member${community.followerCount === 1 ? '' : 's'})`,
    type: OUTBOUND_RECIPIENT_TYPE.COMMUNITY,
    count: community.followerCount,
  };
}

export function createCustomerRecipient(customer: Partial<Customer>): Recipient {
  return {
    id: customer.id ?? null,
    name: `${customer.name} (${customer.followerCount} member${customer.followerCount === 1 ? '' : 's'})`,
    type: OUTBOUND_RECIPIENT_TYPE.COMMUNITY,
    count: customer.followerCount,
  };
}

export function createUserRecipient(user: Profile): Recipient {
  return {
    id: user.id,
    name: `${user.name} (${user.email ?? user.phoneNumber})`,
    type: OUTBOUND_RECIPIENT_TYPE.USER,
  };
}

export function isUrl(str: string) {
  str = str.trim();

  if (!str.startsWith('https://') && !str.startsWith('http://')) {
    str += 'https://';
  }

  try {
    new URL(str);

    return true;
  } catch {
    return false;
  }
}

export function findMedian(arr: number[]) {
  if (!arr.length) {
    return undefined;
  }

  const s = [...arr].sort((a, b) => a - b);
  const mid = Math.floor(s.length / 2);

  return s.length % 2 === 0 ? (s[mid - 1] + s[mid]) / 2 : s[mid];
}

export function sleep(ms: number) {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

export function getHtmlFromSlackMd(message: string) {
  const parsed = slackMessageParser(message);

  const _toHtml = (node: Node, isRoot: boolean): string => {
    switch (node.type) {
      case NodeType.Root:
        return `${node.children.map((c) => _toHtml(c, isRoot)).join('')}`;
      case NodeType.Text:
        if (isRoot) {
          return _.escape(_.unescape(node.text));
        }

        return _.escape(_.unescape(node.text));
      case NodeType.Bold:
        return `<strong>${node.children.map((c) => _toHtml(c, false)).join('')}</strong>`;
      case NodeType.Italic:
        return `<i>${node.children.map((c) => _toHtml(c, false)).join('')}</i>`;
      case NodeType.Strike:
        return `<del>${node.children.map((c) => _toHtml(c, false)).join('')}</del>`;
      case NodeType.URL:
        return `<a href="${_.escape(node.url)}" target="_blank">${
          node.label ? node.label.map((c) => _toHtml(c, false)).join('') : _.escape(node.url)
        }</a>`;
      case NodeType.Code: {
        return `<code class="code">${node.text}</code>`;
      }
      case NodeType.PreText: {
        // replace the opening and closing new line characters following triple backticks
        return `<pre class="code">${node.text.replace(/^\n|\n$/g, '')}</pre>`;
      }
      case NodeType.Emoji:
        const emoji = Data?.emojis[node.name]?.skins?.[0];

        if (emoji?.native) {
          return emoji.native;
        } else if (emoji?.src) {
          return `<img src="${emoji.src}" alt="${node.name}" class="custom-emoji" />`;
        }

        return _.escape(node.source);
      case NodeType.Quote:
        return `<blockquote class="blockquote">${node.children.map((c) => _toHtml(c, false)).join('')}</blockquote>`;
      case NodeType.UserLink:
        // @ts-ignore
        return `<span class="mention-tag">@${_.escape(node.label?.[0]?.text ?? node.userID)}</span>`;
      case NodeType.ChannelLink:
        // @ts-ignore
        return `<span class="mention-tag">#${_.escape(node.label?.[0]?.text ?? node.channelID)}</span>`;
      case NodeType.Command:
        return `<span class="code">@${_.escape(node.name ?? 'Command')}</span>`;
      default:
        // @ts-ignore
        return _.escape(node.source);
    }
  };

  return `<p>${_toHtml(parsed, true)}</p>`.replace(/\n/g, '<br/>').trim();
}

function formatUsername(contact: { name?: string | null; email?: string | null }) {
  return contact.name || contact.email;
}

function formatLink(userId: string, isExternal: boolean) {
  return `/${routes.PEOPLE}${isExternal ? '/external' : ''}/${userId}`;
}

function replaceEmoji(text: string): string {
  return text.replace(/:(.+?):/g, (match, p1) => Data?.emojis[p1]?.skins?.[0]?.native ?? match);
}

export function formatSlackContent(resolvedContent: any[] = []) {
  if (!resolvedContent.length) {
    return '(no message content)';
  }

  return resolvedContent.reduce((acc, next) => {
    if (typeof next === 'object') {
      if (next.type === 'user') {
        return acc + `@${_.escape(next.name ?? 'User')}`;
      } else if (next.type === 'channel') {
        return acc + `#${_.escape(next.name ?? 'channel')}`;
      } else {
        return acc + `${_.escape(next.name ?? next.id ?? 'link')}`;
      }
    }

    return acc + replaceEmoji(_.unescape(next));
  }, '');
}

const SOURCE_TYPE_LABELS = {
  [MESSAGE_SOURCE_TYPE.DASHBOARD]: 'Dashboard',
  [MESSAGE_SOURCE_TYPE.EMAIL]: 'Email',
  [MESSAGE_SOURCE_TYPE.WIDGET]: 'Widget',
  [MESSAGE_SOURCE_TYPE.TEAMS]: 'Microsoft Teams',
  [MESSAGE_SOURCE_TYPE.SLACK]: 'Slack',
};

export function transformMessage(message: any) {
  if (!message) {
    return message;
  }

  const user = message.sentByUser;
  const userId = user?.id;
  const widgetSession = message.inbound?.widgetMessage?.widgetSession;
  let name = null;
  let link = null;
  let image = null;
  const type = SOURCE_TYPE_LABELS[message.sourceType] ?? SOURCE_TYPE_LABELS.SLACK;
  let userType = 'customer';
  let isVerified = true;
  let email = null;

  if (user) {
    name = formatUsername(user);
    email = user.email;
    link = formatLink(user.id, user.isExternal);
    image = user.photo;

    if (!user.isExternal) {
      if (user.slackChannelMember?.isExternal) {
        userType = 'internal_customer';
      } else {
        userType = 'internal';
      }
    }
  } else if (message.botId) {
    name = message.botUsername ?? message.botName;
    image = message.botImage ?? undefined;
    userType = 'bot';
  }

  if (message.sourceType === MESSAGE_SOURCE_TYPE.EMAIL) {
    isVerified = false;
  } else if (message.sourceType === MESSAGE_SOURCE_TYPE.WIDGET) {
    isVerified = widgetSession?.isVerified ?? false;
  }

  if (!name) {
    name = 'No name';
  }

  return {
    ...message,
    userId,
    name,
    email,
    image,
    link,
    userType,
    type,
    isVerified,
    botId: message.botId,
  };
}

export function delay(ms: number) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(undefined), ms);
  });
}

interface AppConfig {
  thirdPartyCookiesEnabled?: boolean;
  subdomain?: string;
  tenantId?: string;
  logo?: string;
  primaryColor?: string;
  authProviders?: string[];
  domains?: string[];
  homePageFilters?: string[];
  analyticsDisabled: boolean;
  phoneNumberRequirement: string;
  customProfileFields?: any[];
  useLegacyAuthDomain: boolean;
  workos?: {
    directoryId?: string;
  };
  defaultCommunityId?: string;
  defaultFieldSettings?: Record<string, { public?: boolean }>;
  permissions?: {
    canCreatePlans?: boolean;
    canCreatePosts?: boolean;
    canCreateOrganizers?: boolean;
    canViewPeople?: boolean;
  };
  type?: string;
  slackTeamId?: string;
  slackTeamName?: string;
  accountStatus?: string;
  emailSupport?: {
    enabled?: boolean;
    slackChannelId?: string;
  };
  googleAnalyticsTrackingId?: string;
  slackClientId?: string;
  slackAppId?: string;
}

let appConfig: AppConfig | undefined;

export function getAppConfig() {
  if (!appConfig) {
    const inlineJsonElement = document.querySelector(
      `script[type="application/json"][data-selector="unthread-config"]`,
    );

    appConfig = JSON.parse(inlineJsonElement.textContent);
  }

  return appConfig;
}

export function getCspNonce() {
  const nonce = (document.querySelector('meta[name="CSP_NONCE"]') as HTMLMetaElement)?.content;

  return nonce;
}

export async function downloadSlackAttachment(apiService: ApiService, attachmentId: string, slackTeamId?: string) {
  const response: any = await apiService.download(
    `/slack/files/${attachmentId}/download${slackTeamId ? `?teamId=${slackTeamId}` : ''}`,
    'GET',
  );

  const contentDisposition: string = response.headers.get('content-disposition');
  const filename =
    contentDisposition
      .split(';')
      .find((p) => p.startsWith('filename'))
      ?.split('=')[1] ?? 'file.txt';

  const binaryData = [];

  binaryData.push(response.body);

  const downloadLink = document.createElement('a');

  downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
  downloadLink.setAttribute('download', filename);

  document.body.appendChild(downloadLink);

  downloadLink.click();
  downloadLink.remove();
}

export function getIconForConversationPriority(priority?: number) {
  switch (priority) {
    case CONVERSATION_PRIORITY_VALUES.LOW:
      return 'remove-outline';
    case CONVERSATION_PRIORITY_VALUES.MEDIUM:
      return 'reorder-two-outline';
    case CONVERSATION_PRIORITY_VALUES.HIGH:
      return 'reorder-three-outline';
    case CONVERSATION_PRIORITY_VALUES.CRITICAL:
      return 'reorder-four-outline';
  }

  return '';
}

export function getDisplayTextForDuration(durationSecs: number) {
  const ONE_DAY = 60 * 60 * 24;
  const ONE_HOUR = 60 * 60;
  const ONE_MINUTE = 60;

  const days = Math.floor(durationSecs / ONE_DAY);
  const hours = Math.floor((durationSecs - days * ONE_DAY) / ONE_HOUR);
  const minutes = Math.floor((durationSecs - hours * ONE_HOUR - days * ONE_DAY) / ONE_MINUTE);

  const dayLabel = days ? `${days} day${days === 1 ? '' : 's'}` : '';
  const hourLabel = hours ? `${hours} hour${hours === 1 ? '' : 's'}` : '';
  const minuteLabel = `${minutes === 0 ? '< 1' : minutes} minute${minutes <= 1 ? '' : 's'}`;

  return `${dayLabel} ${hourLabel} ${minuteLabel}`;
}

export function getSlackLink(
  teamId: string,
  messageTs?: string,
  threadTs?: string,
  channelId?: string,
  slackTeamUrl?: string,
) {
  if (slackTeamUrl) {
    let baseUrl = `${slackTeamUrl}archives/${channelId}`;
    const query = [`cid=${channelId}`];

    if (messageTs) {
      baseUrl += `/p${messageTs.replace('.', '')}`;

      query.push(`thread_ts=${threadTs ?? messageTs}`);
    }

    return `${baseUrl}?${query.join('&')}`;
  }

  const baseUrl = `slack://channel`;
  const query = [`team=${teamId}`, `id=${channelId}`];

  if (messageTs) {
    query.push(`message=${messageTs}`);
  }

  if (threadTs || messageTs) {
    query.push(`thread_ts=${threadTs ?? messageTs}`);
  }

  return `${baseUrl}?${query.join('&')}`;
}

export function generateRandomString(length = 6) {
  return Math.random()
    .toString(36)
    .substring(2, length + 2);
}

export function getWhereClausesFromFilters(selectedFilterMap: SelectedFilterMap) {
  return _.flatMap(Array.from(selectedFilterMap.entries()), ([option, values]) => {
    if (!option.type || option.type === 'select') {
      if (option.grouping === 'and') {
        return values.map((f) => {
          return {
            field: option.id,
            operator: 'contains',
            value: [f.id],
          };
        });
      }
      return [
        {
          field: option.id,
          operator: option.id === 'tags.id' || option.id === 'customer.tags.id' ? 'contains' : 'in',
          value: values.map((v) => v.id),
        },
      ];
    }

    if (option.type === 'date') {
      const result = [
        {
          field: option.id,
          operator: '>=',
          value: values[0].id,
        },
      ];

      if (values[1]) {
        result.push({
          field: option.id,
          operator: '<=',
          value: values[1].id,
        });
      }

      return result;
    }
  });
}

export function getEmailDomain(email?: string) {
  return email?.split('@')?.[1] ?? email ?? '';
}

export function attachEventListenerToElement(
  query: string,
  type: string,
  listener: EventListenerOrEventListenerObject,
  options?: boolean | AddEventListenerOptions,
) {
  const element = document.querySelector(query);

  if (element) {
    element.addEventListener(type, listener, options);
  }

  return {
    remove: () => {
      if (element) {
        element.removeEventListener(type, listener, options);
      }
    },
  };
}

export function generateCustomFieldsArray(
  defaultFields: any[],
  currentFields: any[],
  additionalKeyValueFields: Record<string, unknown>,
) {
  const fields = [];
  defaultFields.forEach((defaultField) => {
    // Check if this is mapped to a field on the profile
    if (defaultField.fieldKey) {
      fields.push({
        ...defaultField,
        value: _.get(additionalKeyValueFields, defaultField.fieldKey, ''),
      });
      return;
    }

    let hasHit = false;
    if (currentFields) {
      currentFields.forEach((field) => {
        if (getCustomFieldId(defaultField) === getCustomFieldId(field)) {
          // Take the value given, but any other metadata (like required or visible) should inherit from company setting.
          // For multi-select or multiple choice, this is tricky because they may have
          //  changed the options and we want to maintain their selection
          const options = defaultField.options
            ? _.uniqBy([...(field?.options ?? []), ...(defaultField?.options ?? [])], (f) => f)
            : null;

          fields.push({
            ...defaultField,
            value: field?.value,
            options,
          });
          hasHit = true;
        }
      });
    }

    if (!hasHit) {
      fields.push({
        ...defaultField,
      });
    }
  });
  return fields;
}

export function getRandomColor(): string {
  const getRandomValue = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  // Generate random values for hue, saturation, and lightness
  const hue = getRandomValue(0, 360);
  const saturation = getRandomValue(10, 50); // Keep saturation between 60% and 100%
  const lightness = getRandomValue(60, 80); // Keep lightness between 40% and 70%

  // Construct the HSL color string
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}
